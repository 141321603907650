import React from 'react';
import { WebsiteLayout } from '@components/common/layout';
import { Home } from '@components/screens';

function PageWithLayout({ user, logout, onAuthSuccess = () => {} }) {
  return (
    <WebsiteLayout user={user} handleLogout={logout}>
      <Home />
    </WebsiteLayout>
  );
}

export default PageWithLayout;
